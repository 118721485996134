/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.655);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
}

/* Slide in/out animations */
@keyframes slideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.slide-in {
  animation-name: slideIn;
}

.slide-out {
  animation-name: slideOut;
}

/* Popup Content */
.popup-content {
  background-color: rgba(43, 43, 43, 0.923);
  border-color: #23B5B5;
  border-style: solid;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  position: relative;
  max-height: 500vh; 
  overflow-y: auto; 
}

/* Close Button */
.close-popup-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color:#23B5B5;
}

/* Book Now Button */
.book-now-btn {
  padding: 10px 20px;
  background-color: #23B5B5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  animation: pulse 1.5s infinite;
}

.book-now-btn:hover {
  background-color: #098787;
}
